import TYPES from '@/types';
import Vue from 'vue';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';

export default class MoreInvestmentAlternativesDialogViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.allianz-dashboard.more-investment-alternatives.information';

  readonly view: Vue;

  width_percent = '60%';

  video_src = {
    webm: 'https://dv1k4uibauddz.cloudfront.net/mis_metas.webm',
    mp4: 'https://dv1k4uibauddz.cloudfront.net/mis_metas.mp4',
  }

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get dynamic_width() {
    // eslint-disable-next-line no-nested-ternary
    return (this.view.$vuetify.breakpoint.xs) ? '90%' : (this.view.$vuetify.breakpoint.sm) ? '80%' : this.width_percent;
  }

  start = () => {
    this.view.$emit('start');
  }
}
