












































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import MoreInvestmentAlternativesDialogViewModel
  from '@/vue-app/view-models/allianz-dashboard/more-investment-alternatives/more-investment-alternatives-dialog-view-model';

@Component({
  name: 'MoreInvestmentAlternativesDialog',
  components: {},
})
export default class MoreInvestmentAlternativesDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  more_investment_alternatives_model = Vue.observable(
    new MoreInvestmentAlternativesDialogViewModel(this),
  );

  closeModal() {
    this.synced_is_open = false;
  }

  start() {
    this.more_investment_alternatives_model.start();
    this.synced_is_open = false;
  }
}
